import { Button, Card, Form } from "react-bootstrap";

function LoginUI(props) {
    // const Formik = props.Formik
    const {
        Formik,
        active,
        handleCancel,
        handleSignup,
        login
    } = props

    return(
        <Card className={`mx-4 Login-card ${active ? "active" : ""}`} ref={login}>
            <Card.Body className="Login-card-body">
                <h2>Login</h2>
                <Form onSubmit={Formik.handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                        {/* <Form.Label>email</Form.Label> */}
                        <Form.Control
                            type="email"
                            name="email"
                            label="email"
                            placeholder="enter email"
                            value={Formik.values.email}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            isValid={Formik.touched.email && !Formik.errors.email}
                            isInvalid={Formik.touched.email && Formik.errors.email}
                            required
                        />
                        <Form.Control.Feedback type="inValid">
                            {Formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                        {/* <Form.Label>password</Form.Label> */}
                        <Form.Control
                            type="password"
                            name="password"
                            label="password"
                            placeholder="enter password"
                            value={Formik.values.password}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            isValid={Formik.touched.password && !Formik.errors.password}
                            isInvalid={Formik.touched.password && Formik.errors.password}
                            required
                        />
                        <Form.Control.Feedback type="inValid">
                            {Formik.errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group id="account-type" className="mb-4">
                        <Form.Label>Choose your account type</Form.Label>
                        <Form.Select
                            name="accountType"
                            value={Formik.values.accountType}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                        >
                            <option>select from list</option>
                            <option value="lead">Dev Lead/Manager</option>
                            <option value="dev">Dev</option>
                            <option value="recruiter">Recruiter</option>
                            <option value="member">Member</option>
                        </Form.Select>
                    </Form.Group>
                    <Button className="w-100 Login-submit mb-3" type="submit">Login</Button>
                    <Button className="w-100 Login-cancel btn-cancel" onClick={handleCancel}>Cancel</Button>
                </Form>
                <div>dont't have an account? <a href="/#" onClick={handleSignup}>Sign up</a></div>
            </Card.Body>
        </Card>
    )
}

export default LoginUI;