import { Card, Form, Button } from "react-bootstrap";

function SignupUI(props) {
    const Formik = props.Formik
    const handleSignIn = props.handleSignIn

    return(
        <Card>
            <Card.Body>
                <h2>Sign Up</h2>
                <Form onSubmit={Formik.handleSubmit}>
                    <Form.Group id="email" className="mb-2">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="email"
                            placeholder="email@domain.tld"
                            name="email"
                            value={Formik.values.email}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            isValid={Formik.touched.email && !Formik.errors.email}
                            isInvalid={Formik.touched.email && Formik.errors.email}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {Formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group id="password" className="mb-2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="enter password"
                            name="password"
                            value={Formik.values.password}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            isValid={Formik.touched.password && !Formik.errors.pasword}
                            isInvalid={Formik.touched.password && Formik.errors.password}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {Formik.errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group id="confirm-password" className="mb-2">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="confirm password"
                            name="confirmPassword"
                            value={Formik.values.confirmPassword}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            isValid={Formik.touched.confirmPassword && !Formik.errors.confirmPassword}
                            isInvalid={Formik.touched.confirmPassword && Formik.errors.confirmPassword}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {Formik.errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group id="account-type" className="mb-4">
                        <Form.Label>Choose your account type</Form.Label>
                        <Form.Select
                            name="accountType"
                            value={Formik.values.accountType}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                        >
                            <option>select from list</option>
                            <option value="lead">Dev Lead/Manager</option>
                            <option value="dev">Dev</option>
                            <option value="recruiter">Recruiter</option>
                            <option value="member">Member</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {Formik.errors.accountType}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                        disabled={!(Formik.isValid && Formik.dirty)}
                        className="w-100"
                        type="submit"
                        >
                        Sign Up
                        </Button>
                </Form>
            </Card.Body>
            <div className="w-100 text-center mt-2">
                Already have an account? <a href="/signup#" onClick={handleSignIn} style={{color: "#333"}}>Sign In</a>
            </div>
        </Card>
    )
}

export default SignupUI;