export const changeFocus = text => ({
    type: 'CHANGE_FOCUS',
    text
})

export const changeProject = id => ({
    type: 'CHANGE_PROJECT',
    id
})

export const activate_signin = () => ({
    type: 'ACTIVATE_SIGNIN',
})

export const deactivate_signin = () => ({
    type: 'DEACTIVATE_SIGNIN',
})