import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import List from "./lists";

function Experience() {
    
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    
    const fetchData = () => {
        fetch("https://api.irvingluna.com/wp-json/experience/v1/all")
          .then( res => res.json())
          .then(
            (result) => {
              setLoading(false)
              setData(result)
            }
        )
    }
    
    useEffect(() => {
        fetchData()
    }, [])

    var component = (
        <div className="h-100 d-flex bg-dark text-white overflow-scroll Experience-component">
            <div className="w-100 ">
                <ul className="list-group p-3">
                    {
                        data.map( (value, key) => {
                            return (
                                <li className="list-group-item m-3" key={key}>
                                    <div className="Container">{value.post_title}</div>
                                    <List data={value} />
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )

    var loading = (
        <div className="Loader">
            <Loader type="Puff" color="#00BFFF" height={100} width={100} timeout={3000} />
        </div>
    )

    return isLoading ? loading : component
}

export default Experience;