import injectStyle from "../../../../../../../helpers/inject-style";

function Proficiencies(props) {

    const keyframesStyle = `
        @keyframes loadBar {
            0% { width: 0; }
            100% { width: 100%; }
    `;
    injectStyle(keyframesStyle)
    return (
        <li className="list-group-item bg-dark m-2">
            <div className="
                d-flex
                flex-lg-row flex-column
                align-items-center
                text-white">
                <div className="
                    d-flex
                    col-lg-3 col-12
                    justify-content-center
                    text-center
                    ">
                        <div className="
                            d-inline
                            ">
                            {props.data.label}
                        </div>
                </div>
                <div className="
                    d-flex
                    col-lg-9 col-12
                    p-3
                    ">
                    <div className="
                        w-100
                        bg-white
                        overflow-hidden
                        Proficiencies-value
                        ">
                        {/* style={{width: "calc(" + props.data.proficiency/10  + " * 100%)", transition: 'width 3s'}} */}
                        <span 
                            className="Span-animated"
                            style={{
                                maxWidth: "calc(" + props.data.proficiency/10 + " * 100%)"
                            }} />
                    </div>
                </div>
            </div>
        </li>
    )
}

export default Proficiencies;