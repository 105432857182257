import AppFocus from './app-focus'

function AppBody(props) {

    return (
        <div className="
          h-100
          col-12
          d-flex
          rounded
          overflow-hidden">
          <AppFocus data={props.data} focus="projects"/>
        </div>
    )
}

export default AppBody;