const apiURL = process.env.REACT_APP_WP_API_URL

export function signup(email, password, accountType) {
    console.log("signup")
    var key = getAuthKey(accountType)
    return fetch(apiURL + "/?rest_route=/login/v1/users&email="+email+"&password="+password+"&AUTH_KEY="+key, {
        method: 'POST',
    })
}

export function refresh(jwt) {
    return fetch(apiURL+"?rest_route=/login/v1/auth/validate&JWT"+jwt, {
        method: 'POST',
    })
}

export function authenticate(email, password, accountType) {
    var key = getAuthKey(accountType)
    return fetch (apiURL + "/?rest_route=/login/v1/auth&email="+email+"&password="+password+"&AUTH_KEY="+key, {
        method: 'POST',
    })
}

export function validate(jwt) {
    return fetch(apiURL + "/?rest_route=/login/v1/auth/validate&JWT="+jwt)
}

export function revoke(jwt) {
    return fetch(apiURL + "/?rest_route=/login/v1/auth/validate&JWT"+jwt, {
        method: 'POST',
    })
}

function getAuthKey(accountType) {
    if(accountType === "lead") {
        return process.env.REACT_APP_WP_LEAD
    }
    if(accountType === "dev") {
        return process.env.REACT_APP_WP_DEV
    }
    if(accountType === "recruiter") {
        return process.env.REACT_APP_WP_RECRUITER
    }
    if(accountType === "member") {
        return process.env.REACT_APP_WP_SUBSCRIBER
    }
}