import { useDispatch } from 'react-redux'
import { changeProject } from '../../../../../../actions';

function Projects(props) {

  const dispatch = useDispatch()

  var element = (
    <div className="">
      {
        props.data.map( (value, key) => {
          var platform = value.ACF.platforms.platform
          var name = value.ACF.main_info.project_info.name
          return (
            <div className="Project-id" key={key} onClick={() => dispatch(changeProject(key))}>
              <span className={platform}></span> | {name}
            </div>
          )
        })
      }
    </div>
  )

  return element
}

export default Projects;