import { useFormik } from "formik";
import { Container } from "react-bootstrap";
import * as yup from 'yup';
import Alert from "react-popup-alert"
import 'react-popup-alert/dist/index.css'
import SignupUI from "./index.ui";
import { useState } from "react";
import Login from "../../../login";
import { useDispatch } from "react-redux";
import { activate_signin } from "../../../../actions";
import { useAuth } from "../../../Authorization";
import { Redirect } from "react-router-dom";

function SignupPage() {

    const auth = useAuth()
    const dispatch = useDispatch()

    const [alert, setAlert] = useState({
        message: 'error',
        text: 'This is a alert message',
        show: false
    })

    function onCloseAlert() {
        setAlert({
            message: '',
            show: false
        })
    }

    function onShowAlert(message) {
        setAlert({
            message: message,
            show: true
        })
    }
    
    const initialValues = {
        results: "",
        email: "",
        password: "",
        confirmPassword: "",
        accountType: ""
    }

    const validationSchema = yup.object({
        results: yup
            .string(),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required("Email is required"),
        password: yup
            .string("Enter password")
            .required("Password is required"),
        confirmPassword: yup
            .string("Confirm password")
            .oneOf([yup.ref('password'),null], 'Passwords do not match')
            .required("Please confirm password"),
        accountType: yup
            .string("")
            .required("Account type is required")
    })  

    function handleSubmit(values, actions) {
        if (auth.user.user) {
            return
        }
        auth.signup(values.email, values.password, values.accountType)
        .then(res => {
            if(res.success === true){
                onShowAlert(res.message)
                // After user clicks continue on popup 
                // slide component off screen and show user request form
            } else {
                onShowAlert(res.data.message)
                actions.setSubmitting(false)
            }
        })
    }
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => handleSubmit(values, actions)
    })

    const activateSignIn = () => {
        dispatch(activate_signin())
    }

    return !auth.user.user ? (
        <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
            <div className="w-100" style={{maxWidth: "400px" }}>
                {
                    auth.user ? <p>You are logged in {auth.user.display_name}</p> : <p>You are not logged in</p>
                }
                <SignupUI Formik={formik} handleSignIn={activateSignIn}/>
                <Alert
                    header="Error"
                    btnText="Close"
                    text={alert.message}
                    type='error'
                    show={alert.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    showBorderBottom={true}
                    alertStyles={{}}
                    headerStyles={{width: "100%", backgroundColor: "red", display: "flex", justifyContent: "center", text: "white"}}
                    textStyles={{}}
                    buttonStyles={{}}
                />
            </div>
            <Login style={{height: "100vh"}} />
        </Container>
    ) : <Redirect to={{
            pathname: "/",
        }} />
}

export default SignupPage;