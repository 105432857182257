import { useFormik } from "formik";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from 'yup';
import { deactivate_signin } from "../../actions";
import LoginUI from "./login.ui";
import { useAuth } from "../Authorization"

function Login() {
    const auth = useAuth()
    const login = useRef()
    const location = useLocation()
    const signinRedux = useSelector(state => state.signinRedux)
    const dispatch = useDispatch()

    useEffect( () => {
        document.addEventListener("mousedown", handleClick);

        return () => document.removeEventListener("mousedown", handleClick)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (e) => {
        if(!login.current.contains(e.target)) {
            if(e.target.classList.contains("active")) {
                dispatch(deactivate_signin())
            }
        }
    }

    const handleCancel = (e) => {
        dispatch(deactivate_signin())
    }

    const handleSignup = (e) => {
        e.preventDefault()
        if (location.pathname === "/signup") {
            dispatch(deactivate_signin())
            return
        }
        window.location.replace("/signup");
    }

    const initialValues = {
        email: "",
        password: "",
        accountType: ""
    }

    const validationSchema = yup.object({
        email: yup
            .string()
            .email("Enter a valid email")
            .required("Email is required"),
        password: yup
            .string()
            .required("Password is required"),
        accountType: yup
            .string()
            .required("Account type is required")
    })

    function handleSubmit(values, actions) {
        if ( auth.user.user ) {
            return 
        }

        auth.signin(values.email, values.password, values.accountType)
            .then(res=>{
                if (res.success === true) {
                    dispatch(deactivate_signin())
                } else {
                    // popup alert unable to log in
                }
            })
    }

    const formick = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => handleSubmit(values, actions)
    })

    return (
        <div className={`position-absolute w-100 px-1 d-flex justify-content-center align-items-center Login-container ${signinRedux ? "active" : ""}`}>
            <LoginUI 
                Formik={formick}
                active={signinRedux}
                login={login}
                handleCancel={handleCancel}
                handleSignup={handleSignup}
            />
        </div>
    )
}

export default Login;