import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import * as JWT from "./JWT";

const authContext = createContext()

export function ProvideAuth({children}) {
    const auth = useProvideAuth()
    return (
        <authContext.Provider value={auth}>{children}</authContext.Provider>
    )
}

export function useAuth() {
    return useContext(authContext)
}

const initialValue = {
    jwt: "",
    user: null,
    authenticated: false
}

function useProvideAuth() {
    const [user, setUser] = useState(initialValue)
    const [cookies, setCookies] = useCookies(['ils_user_cookies'])

    const signin = (email, password, accountType) => {
        console.log("signingIn")
        return JWT
            .authenticate(email, password, accountType)
            .then(res=>res.json())
            .then(res=>{
                if (res.success === true) {
                    setCookies('ils_user_cookies', res.data.jwt, {path: '/'})
                    validate(res.data.jwt)
                } else {
                    // display error signing in
                }
                return res
            })
    }

    const signup = (email, password, accountType) => {
        console.log("signup")
        return JWT
            .signup(email, password, accountType)
            .then(res=> res.json())
            .then(res=>{
                if ( res.user ) {
                    signin(email, password, accountType)
                }
                return res
            })
    }

    const signout = () => {
        return true
    }

    const sendPasswordResetEmail = (email) => {
        return true
    }

    const confirmPasswordResetEmail = (email) => {
        return true
    }

    const validate = (jwt) => {
        JWT.validate(jwt)
        .then(res=>res.json())
        .then(res=>{
            if(res.success === true){
                setUser({
                    jwt: cookies.ils_user_cookies,
                    user: res.data.user,
                    authenticated: true
                })
            } else {
                setUser({
                    jwt: cookies.ils_user_cookies,
                    user: null,
                    authenticated: true
                })
            }
        })
    }
    
    useEffect(() => {
        if (!cookies.ils_user_cookies) {
            setUser({
                jwt: "",
                user: null,
                authenticated: true
            })
            return
        }
        validate(cookies.ils_user_cookies)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        user,
        signin,
        signup,
        signout,
        sendPasswordResetEmail,
        confirmPasswordResetEmail
    }
}
