import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { ProvideAuth } from './Authorization';
import AdminPage from './pages/protected/Admin';
import HomePage from './pages/public/Home';
import SignupPage from './pages/public/Signup';

function AppUI() {

    return (
        <ProvideAuth>
            <Router>
                <Switch>
                    <Route exact path="/" >
                        <HomePage />
                    </Route>
                    <Route path="/signup">
                        <SignupPage />
                    </Route>
                    <Route path="/admin">
                        <AdminPage />
                    </Route>
                    <Route path="/redirect" >
                        <Redirect to="/admin" />
                    </Route>
                    <Route path="*" >
                        <Redirect from="*" to="/" />
                    </Route>
                </Switch>
            </Router>
        </ProvideAuth>
    )
}

export default AppUI;