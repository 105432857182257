import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ProjectDescription from "./project-description";
import Projects from "./projects";
import Loader from "react-loader-spinner";


import image from '../../../../../../assets/iphone.jpeg'

function ProjectsComponent(props) {
    const projectID = useSelector( state => state.appProject )
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const ref = useRef(null);

    const fetchData = () => {
        fetch("https://api.irvingluna.com/wp-json/projects/v1/all")
        .then( res => res.json())
        .then(
            (result) => {
                setLoading(false)
                setData(result)
            }
        )
    }

    useEffect( () => {
        fetchData()
    }, [])

    var element = (
        <div className="
            h-100
            d-flex
            text-white">
            <div className="
                col-md-4
                p-3
                bg-dark
                Project-options">
                <Projects data={data} />
            </div>
            <div className="col-md-8 p-3 Project-description">
                <ProjectDescription data={data[projectID]}/>
            </div>
        </div>
    )

    var loader = (
        <div className="Loader">
            <Loader type="Puff" color="#00BFFF" height={100} width={100} timeout={3000} />
        </div>
    )

    return (
        <div className="h-100" ref={ref} style={{backgroundImage: `url(${image})`}}>
            { !isLoading ? element : loader }
        </div>
    )
}

export default ProjectsComponent;