import { useSelector } from 'react-redux'
import ProjectsComponent from './projects/projects-component';
import Experience from './experience'

function AppFocus() {
  const focusID = useSelector( state => state.appFocus )
  var body;
  if (focusID === "projects") {
    body = <ProjectsComponent />
  } else if (focusID === "experience") {
    body = <Experience />
  } else if (focusID === "admin") {
    body = <div>hello</div>
  }

  return (
    <div className="
      h-100
      w-100
      ">
        {body}
    </div>
  )
}

export default AppFocus;