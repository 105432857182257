import {v4 as uuidv4} from 'uuid'

function ProjectDescription(props) {
    if (props.data !== undefined) {
        return(
            <div className="Project-description-data">
                <h3>{props.data.ACF.main_info.project_info.name}</h3>
                <strong>launched: </strong>
                {
                    props.data.ACF.platforms.platform.map( (value) => {
                        return <span className={value} key={uuidv4()}>{value}</span>
                    })
                }
                {
                    // props.data.platform.map( (value, key) => {
                    //     return (
                    //         <div  className="ios-download" key={key}>
                    //             <img src={value.linkURL} alt=""/><br/>
                    //         </div>
                    //     )
                    // })
                }
                <strong>Technology:</strong>                    
                <div className="Project-technologies">
                    {
                        props.data.ACF.technologies.technologies.map( (value, key) => {
                            return <span className={value} key={uuidv4()}>{value}</span>
                        })
                    }
                </div>

                <div>Git: <a href={props.data.ACF.demo_git}>source_code</a></div>
            <p>
            {props.data.ACF.main_info.project_description}
            </p>
            </div>
        )
    } else {
        return <div>Loading</div>
    }
}

export default ProjectDescription;