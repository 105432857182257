import Login from "../../../login";
import LoginNav from "../../../LoginNav";
import AppBody from "./components/app-body";
import AppHeader from "./components/app-header";

function HomePageUI(props) {
    const {
        signinRedux,
        refs,
        deactivateSignIn
    } = props


    return (
        <div className="Page">
            <LoginNav />
            <div
                className={"d-flex flex-md-row flex-column Home-page"}
                ref={refs}
                >
                <div className="
                    col-md-3 col-12
                    h-md-100 h-25
                    d-flex 
                    bg-dark
                    text-white
                    ">
                    <AppHeader />
                </div>
                <div className="
                    flex-col-10
                    h-md-100 h-75
                    col-md-9 col-12
                    p-3
                    ">
                    <AppBody />
                </div>
                <Login active={signinRedux} setActive={deactivateSignIn}/>
            </div>
        </div>
    )
}

export default HomePageUI;