import { useDispatch, useSelector } from "react-redux";
import { activate_signin } from "../../../../actions";
import { useAuth } from "../../../Authorization"
import HomePageUI from "./index.ui";
import { useEffect, useRef } from "react";


function HomePage() {
    const signinRedux = useSelector(state => state.signinRedux)
    const dispatch = useDispatch()
    const auth = useAuth()
    const divBodyRef = useRef()

    const deactivateSignIn = () => {
        dispatch(activate_signin())
    }

    const updateState = () => {
        if (auth.user.user) {
            divBodyRef.current.classList.remove("Logged-out")
        } else {
            divBodyRef.current.classList.add("Logged-out")
        }
    }
    
    useEffect(()=>{
        if(auth.user.authenticated){
            updateState()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[auth])

    return (
        <HomePageUI
            auth={auth}
            signinRedux={signinRedux}
            refs={divBodyRef}
            deactivateSignIn={deactivateSignIn}
        />
    )
  }
  
  export default HomePage;