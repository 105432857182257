import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { activate_signin } from "../../actions";
import { useAuth } from "../Authorization";
import LoginNavUI from "./index.ui";

function LoginNav() {
    const dispatch = useDispatch()
    const activateSignIn = (e) => {
        e.preventDefault()
        dispatch(activate_signin())
    }

    const auth = useAuth()
    const navDivRef = useRef()

    const updateState = () => {
        if (auth.user.user) {
            navDivRef.current.classList.remove("Logged-out")
        } else {
            navDivRef.current.classList.add("Logged-out")
        }
    }

    useEffect(()=>{
        if (auth.user.authenticated) {
            updateState()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[auth])
    
    return (
        <LoginNavUI
            navDivRef={navDivRef}
            activateSignIn={activateSignIn}
        />
    )
}

export default LoginNav;