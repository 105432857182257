import DesktopView from "./DesktopView";

function AppOptions() {

    return(
        <div className="
          d-flex
          flex-column
          col-8 p-3
        ">
          <div className="
            d-md-flex d-none
            flex-column
          ">
            <DesktopView />
          </div>
          <div className="
            d-md-none d-flex
            flex-column
          ">
            <DesktopView />
            </div>
        </div>
    )
}

export default AppOptions;