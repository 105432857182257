import image from '../../../../../assets/87C2E36F-3174-4C4E-B38C-1BA99947BCEB.jpeg'
import AppOptions from './HomePageRoutes';

function AppHeader() {
    return (
        <div className="
            d-flex
            flex-md-column 
            align-items-center
            ">
            <div className="d-flex p-3">
                <div className="
                    d-flex
                    rounded-circle
                    overflow-hidden
                    ">
                    <img src={image} alt="" className="" style={{width: "100%"}}/>
                </div>
            </div>
            <AppOptions />
        </div>
    )
}

export default AppHeader;