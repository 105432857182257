import { useDispatch } from "react-redux";
import { changeFocus } from "../../../../../../../actions";

function DesktopView() {
    
    const dispatch = useDispatch();
      
    const openProjects = () => {
        dispatch(changeFocus("projects"))
    }
    const openExperience = () => {
        dispatch(changeFocus("experience"))
    }

    return (
        <>
            <div className="" onClick={openProjects}>
              Open Projects
            </div>
            <div className="" onClick={openExperience}>
              Experience
            </div>
            {/* <div className="" onClick={openExtraActivities}>
              Extra Activities
            </div> */}
        </>
    )
}

export default DesktopView;