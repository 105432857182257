import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function LoginNavUI(props) {
    const {
        navDivRef,
        activateSignIn,
    } = props

    return(
        <Nav
            className="
                justify-content-end
                align-items-center
                Login-nav
            "
            ref={navDivRef}
            as="ul"
            >
            <Nav.Item>
                <Nav.Link
                    className="text-white"
                    as="li"
                    onClick={activateSignIn}
                    >
                    <a
                        href="/#"
                        onClick={activateSignIn}
                        >
                        Login</a>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    className="text-white"
                    as="li"
                    >
                    <Link
                        to="/signup"
                        >
                        Sign Up
                    </Link>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default LoginNavUI;