const signinRedux = (state = false, action) => {
    switch(action.type) {
        case 'ACTIVATE_SIGNIN':
            return true
        case 'DEACTIVATE_SIGNIN':
            return false
        default:
            return state
    }
}

export default signinRedux;