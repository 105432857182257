import Proficiencies from "./proficiencies";

function List(props) {
    var component = (
        <ul className="list-group">{
            props.data.ACF.proficiency_repeater.map( (value,key) => {
                return (
                    <Proficiencies data={value} key={key} />
                )
            })
        }
        </ul>
    )
    
    return component
}

export default List;